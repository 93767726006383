import { combineReducers } from 'redux';
import auth from './custom-slices/authSlice';
import ChangeLayoutMode from './custom-slices/themeLayoutSlice';
import simulation from './custom-slices/simulationSlice';
import chartState from './custom-slices/chartSlice';

const customRootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ChangeLayoutMode,
    auth,
    simulation,
    chartState,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default customRootReducer;

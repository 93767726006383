import { createSlice } from '@reduxjs/toolkit';
import staticData from '../../config/config';

export const initialState = {
  rtlData: staticData.rtl,
  topMenu: staticData.topMenu,
  mode: staticData.mainTemplate,
  loading: false,
  rtlLoading: false,
  menuLoading: false,
  mainContentLoading: false,
  error: null,
};

export const themeLayoutSlice = createSlice({
  name: 'themeLayout',
  initialState,
  reducer: {
    changeLayoutBegin: (state) => {
      state.loading = true;
    },
    changeLayoutSuccess: (state, action) => {
      state.loading = false;
      state.mode = action.payload;
    },
    changeLayoutErr: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeRtlBegin: (state) => {
      state.rtlLoading = true;
    },
    changeRtlSuccess: (state, action) => {
      state.rtlLoading = false;
      state.rtlData = action.payload;
    },
    changeRtlErr: (state, action) => {
      state.rtlLoading = false;
      state.error = action.payload;
    },
    changeMenuBegin: (state) => {
      state.menuLoading = true;
    },
    changeMenuSuccess: (state, action) => {
      state.menuLoading = false;
      state.topMenu = action.payload;
    },
    changeMenuErr: (state, action) => {
      state.menuLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  changeLayoutBegin,
  changeLayoutSuccess,
  changeLayoutErr,
  changeRtlBegin,
  changeRtlSuccess,
  changeRtlErr,
  changeMenuBegin,
  changeMenuSuccess,
  changeMenuErr,
} = themeLayoutSlice.actions;

export default themeLayoutSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessByRefresh } from './authSlice';
import tokenExpiryCheck from '../../utility/tokenExpiryCheck';
import axios from '../../config/dataService/axios';

import { getItem } from '../../utility/localStorageControl';
import candle_dummy_data  from '../../demoData/candlestick-data.json';
import PLData from '../../demoData/pl-chart.json';
import tradingviewData from '../../demoData/tradingview-data.json';
// import SingleSim from '../../demoData/dummy-single-sim-data.json';

export const initialState = {
    loading: false,
    chipData: [],
    totalChips: [],
    totalCandleData: null,

    plSwitchData: [''],
    totalPlData: null,
    tagsList: [],
    totalUniquePlTickerData: [],

    tempSingleSimulationData: [],
    candleApiCallPageCount: 1,
    candleApiCallStatus: false,
    tradingviewChartData: null,
    tradingviewSelectedTicker: null,
    tradingviewSelectedTickerOptions: null,
    tradingviewSelectedTickerInterval: null,
    tradingviewSelectedTickerIntervalOptions: null,

    allIndicatorsDataArr:  [],
    selectedIndicator: null,
    indicatorFormType: null,
    indicatorFormVisibility: false,
    selectedIndicatorToEdit: null,

    selectedIndicatorColor: '#faae2d',
    displayColorPicker: false,
    error: null
};

export const getTotalUniqueTickers = createAsyncThunk('chartSlice/getTotalUniqueTickers', async(a, { rejectWithValue }) => {
    
    // const access_token = getItem('access_token');

    // const apiConfig = {
    //     url: `${process.env.REACT_APP_API_URL}/`, // Endpoint to be added
    //     method: 'GET',
    //     headers: {
    //       'Authorization': `Bearer ${access_token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   };

    // const istokenExpired = tokenExpiryCheck(access_token);
    // if(istokenExpired){
    //   await dispatch(getAccessByRefresh()); // dispatch to be added
    // }
    const totalChipsData = [];
    
    try {

        // const result = await axios(apiConfig);
        
        // result.data  replaces candle_dummy_data
        candle_dummy_data.forEach(data => {
            if(!totalChipsData.includes(data.trading_symbol)){
                totalChipsData.push(data.trading_symbol);
            }
        });

        return {"all_data": candle_dummy_data, "total_unique_tickers": totalChipsData};

    } catch (error) {
        rejectWithValue(error);
    }
});

export const getSimulationsPL = createAsyncThunk('chartSlice/getSimulationsPL', async(value, { rejectWithValue }) => {
    
    // const access_token = getItem('access_token');

    // const apiConfig = {
    //     url: `${process.env.REACT_APP_API_URL}/`, // Endpoint to be added
    //     method: 'GET',
    //     headers: {
    //       'Authorization': `Bearer ${access_token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   };

    // const istokenExpired = tokenExpiryCheck(access_token);
    // if(istokenExpired){
    //   await dispatch(getAccessByRefresh()); // dispatch to be added
    // }
    const totalChipsData = [];
    
    try {

        // const result = await axios(apiConfig);
        
        // result.data  replaces candle_dummy_data
        PLData[value].forEach(data => {
            if(!totalChipsData.includes(data.ticker)){
                totalChipsData.push(data.ticker);
            }
        });

        return {"all_pl_data": PLData[value], "total_unique_tickers": totalChipsData};

    } catch (error) {
        rejectWithValue(error);
    }
});

export const getSingleSimulationData = createAsyncThunk('simulation/getSingleSimulationData', async (data,{ dispatch, rejectWithValue}) => {

    // const state = getState();
    const access_token = getItem('access_token');
  
    const istokenExpired = tokenExpiryCheck(access_token);
    if(istokenExpired){
      await dispatch(getAccessByRefresh());
    }


    let bodyData = {};
    if(data.optimisation_algo_id === null){
        bodyData = {
            "action": data.action,
            "algo_id": data.algo_id,
            "amount": data.amount,
            "base": data.base,
            "buy_at": data.buy_at,
            "duration": data.duration,
            "end_time": data.end_time,
            "follower": data.follower,
            "from_date": data.from_date,
            "instrument_id": data.instrument_id,
            "sell_at": data.sell_at,
            "start_time": data.start_time,
            "stoploss": data.stoploss,
            "to_date": data.to_date
          }
    }else{
        bodyData = {
            "action": data.action,
            "algo_id": data.algo_id,
            "amount": data.amount,
            "base": data.base,
            "buy_at": data.buy_at,
            "duration": data.duration,
            "end_time": data.end_time,
            "follower": data.follower,
            "from_date": data.from_date,
            "instrument_id": data.instrument_id,
            "optimisation_algo_id": data.optimisation_algo_id,
            "sell_at": data.sell_at,
            "start_time": data.start_time,
            "stoploss": data.stoploss,
            "to_date": data.to_date
          }
    }

    // return SingleSim[data.id];

    const apiConfig = {
      url: `${process.env.REACT_APP_API_URL}/simulation/${data.id}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: bodyData,
    };
    
    try {
        const result = await axios(apiConfig);
  
        if (result.data.message) {
          return rejectWithValue(`Simulation Analysis: ${result.data.message}`);
        } 
  
        // simulationBarChart to be removed after api data configured

        return result.data;
    } catch (err) {
      return rejectWithValue('Failed to get analysis data');
    }
});

export const getTradingViewChartData = createAsyncThunk('chartSlice/getTradingViewChartData', async(value, { rejectWithValue }) => {
    
  // const access_token = getItem('access_token');

  // const apiConfig = {
  //     url: `${process.env.REACT_APP_API_URL}/`, // Endpoint to be added
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${access_token}`,
  //       'Content-Type': 'application/json',
  //     },
  //   };

  // const istokenExpired = tokenExpiryCheck(access_token);
  // if(istokenExpired){
  //   await dispatch(getAccessByRefresh()); // dispatch to be added
  // }

  
  try {

      // const result = await axios(apiConfig);
      
      // result.data  replaces tradingviewData

      return tradingviewData;

  } catch (error) {
      rejectWithValue(error);
  }
});

export const getTradingviewCandleDataByTicker = createAsyncThunk('chartSlice/getTradingviewCandleDataByTicker', async(data, { getState, dispatch, rejectWithValue }) => {
  const access_token = getItem('access_token');

  const state = getState();

  const apiConfig = {
      url: `${process.env.REACT_APP_API_URL}/candles/${data.ticker_token}?interval=${data.interval}&page=${state.chartState.candleApiCallPageCount}&per_page=180`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
    };

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  try {

    const result = await axios(apiConfig);

    if(state.chartState.candleApiCallPageCount === 1 ){
      return result.data.data;
    }
    
    return [...state.chartState.tradingviewChartData, ...result.data.data];
    
  } catch (error) {
    rejectWithValue(error);
  }
});


export const chartSlice = createSlice({
    name: 'chartSlice',
    initialState,
    reducers: {
        setChipData: (state, action) => {
            state.chipData = action.payload;
        },
        setTotalChips: (state, action) => {
            state.totalChips = action.payload;
        },
        setPlSwitchData: (state, action) => {
            state.plSwitchData = action.payload;
        },
        setTradingviewChartData: (state, action) => {
          state.tradingviewChartData = action.payload;
        },
        setCandleApiCallPageCount: (state, action) => {
          state.candleApiCallPageCount = action.payload;
        },
        setCandleApiCallStatus: (state, action) => {
          state.candleApiCallStatus = action.payload;
        },
        setTagList: (state, action) => {
          state.tagsList = action.payload;
        },
        setTotalUniqueTickers: (state, action) => {
            state.totalUniquePlTickerData = action.payload;
        },
        setTempSingleSimulationData: (state,action) => {
            state.tempSingleSimulationData = action.payload;
        },
        setTradingviewSelectedTicker: (state, action) => {
          state.tradingviewSelectedTicker = action.payload;
        },
        setTradingviewSelectedTickerOptions: (state, action) => {
          state.tradingviewSelectedTickerOptions = action.payload;
        },
        setTradingviewSelectedTickerInterval: (state, action) => {
          state.tradingviewSelectedTickerInterval = action.payload;
        },
        setTradingviewSelectedTickerIntervalOptions: (state, action) => {
          state.tradingviewSelectedTickerIntervalOptions = action.payload;
        },
        setAllIndicatorsDataArr: (state, action) => {
          state.allIndicatorsDataArr = action.payload;
        },
        setSelectedIndicator: (state, action) => {
          state.selectedIndicator = action.payload;
        },
        setIndicatorFormVisibility: (state, action) => {
          state.indicatorFormVisibility = action.payload;
        },
        setSelectedIndicatorColor: (state, action) => {
          state.selectedIndicatorColor = action.payload;
        },
        setDisplayColorPicker: (state, action) => {
          state.displayColorPicker = action.payload;
        },
        setIndicatorFormType: (state, action) => {
          state.indicatorFormType = action.payload;
        },
        setSelectedIndicatorToEdit: (state, action) => {
          state.selectedIndicatorToEdit = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(getTotalUniqueTickers.pending, (state) => {
            state.loading = true;
          })
          .addCase(getTotalUniqueTickers.fulfilled, (state, action) => {
            state.loading = false;
            state.totalChips = action.payload.total_unique_tickers;
            state.totalCandleData = action.payload.all_data;
          })
          .addCase(getTotalUniqueTickers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(getSimulationsPL.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSimulationsPL.fulfilled, (state, action) => {
            state.loading = false;
            state.totalUniquePlTickerData = action.payload.total_unique_tickers;
            state.totalPlData = action.payload.all_pl_data;
          })
          .addCase(getSimulationsPL.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(getSingleSimulationData.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSingleSimulationData.fulfilled, (state, action) => {
            state.loading = false;
            state.tempSingleSimulationData = [...state.tempSingleSimulationData,action.payload];
          })
          .addCase(getSingleSimulationData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(getTradingviewCandleDataByTicker.pending, (state) => {
            state.loading = true;
          })
          .addCase(getTradingviewCandleDataByTicker.fulfilled, (state, action) => {
            state.loading = false;
            state.tradingviewChartData = action.payload;
            state.candleApiCallStatus = false;

          })
          .addCase(getTradingviewCandleDataByTicker.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
    }
});


export const { 
    setChipData, 
    setTotalChips, 
    setPlSwitchData,
    setTradingviewChartData,
    setCandleApiCallPageCount,
    setCandleApiCallStatus,
    setTagList, 
    setTotalUniqueTickers, 
    setTempSingleSimulationData ,
    setTradingviewSelectedTicker,
    setTradingviewSelectedTickerOptions,
    setTradingviewSelectedTickerInterval,
    setTradingviewSelectedTickerIntervalOptions,
    setAllIndicatorsDataArr,
    setSelectedIndicator,
    setIndicatorFormVisibility,
    setSelectedIndicatorColor,
    setDisplayColorPicker,
    setIndicatorFormType,
    setSelectedIndicatorToEdit
  } = chartSlice.actions;

export default chartSlice.reducer;

import { UilCreateDashboard, UilChart, UilEstate } from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };
  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector('html');
  //   if (topMode) {
  //     html.classList.add('ninjadash-topmenu');
  //   } else {
  //     html.classList.remove('ninjadash-topmenu');
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };
  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'rtl');
  //   } else {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'ltr');
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add('dark-mode');
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove('dark-mode');
  // };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilEstate />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/simulations`}>
        {t('simulations')}
      </NavLink>,
      'simulations',
      !topMenu && <UilChart />,
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/candlestick`}>
        {t('charts')}
      </NavLink>,
      'charts',
      !topMenu && <UilCreateDashboard />,
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/plchart`}>
        {t('PL Charts')}
      </NavLink>,
      'PL Charts',
      !topMenu && <UilCreateDashboard />,
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/tradingview-chart`}>
        {t('TradingView')}
      </NavLink>,
      'TradingView',
      !topMenu && <UilCreateDashboard />,
    ), 

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/index`}>
    //     {t('index')}
    //   </NavLink>,
    //   'index',
    //   !topMenu && <UilCreateDashboard />,
    // ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;

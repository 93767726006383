import jwt_decode from 'jwt-decode';

const tokenExpiryCheck = (token) => {
    const decodedToken = jwt_decode(token);
    const currentDate = new Date();
    
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 > currentDate.getTime()) {

      // token not expired  
      return false;
    }

    // token expired
    return true;
}

export default tokenExpiryCheck;


import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap>
          <div className="ninjadash-authentication-wrap authentication-left">
            <WraperContent />
          </div>
          <div
            className="authentication-right"
            style={{ backgroundImage: `url("${require('../../../static/img/auth-side-bg.png')}")` }}
          ></div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;

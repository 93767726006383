import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { getAccessByRefresh } from './authSlice';
import tokenExpiryCheck from '../../utility/tokenExpiryCheck';
import axios from '../../config/dataService/axios';
import { getItem } from '../../utility/localStorageControl';

import SingleSim from '../../demoData/dummy-single-sim-data.json';

export const initialState = {
    submittedSuccessfully: false,
    instrumentData: null,
    allSimulationData: null,
    algoData: null,
    optimisationAlgoData: null,
    baseData: null,
    followerData: null,
    simulationAnalysisData: null,
    simualtionDailyPLData: null,
    tickerIntervalData: null,
    totalPage: 1,
    itemsPerPage: 100,
    currentPage: 1,
    simulationResultFile: null,
    modalVisibility: false,
    baseExclude: '',
    followerExclude: '',
    selectedAlgo: 'treading',
    selectedTicker: '',
    simulationTableLoading: false,
    error: null,
    loading: false,
    
}


export const getAllSimulation = createAsyncThunk('simulation/getAllSimulation', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    url: data === undefined ? `${process.env.REACT_APP_API_URL}/simulation` : `${process.env.REACT_APP_API_URL}/simulation?page=${data.currentPage}&per_page=${data.itemsPerPage}`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };
  
  try {
      const result = await axios(apiConfig);

      if (result.data.message) {
        return rejectWithValue(result.data.message);
      } 

      return result.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInstrument = createAsyncThunk('simulation/getInstrument', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

    const istokenExpired = tokenExpiryCheck(access_token);
    if(istokenExpired){
      await dispatch(getAccessByRefresh());
    }

    const apiConfig = {
      url: `${process.env.REACT_APP_API_URL}/instrument`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
        const result = await axios(apiConfig);

        if (result.data.message) {
          return rejectWithValue(`Instrument: ${result.data.message}`);
        } 
    
        return result.data.data;
        
    } catch (err) {
      return rejectWithValue(err);
    }
});

export const getAlgo = createAsyncThunk('simulation/getAlgo', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/algo`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await axios(apiConfig);

    if(result.data.message){
      return rejectWithValue(`Algo: ${result.data.message}`);
    }
    
    return result.data.data;

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getOptimizationAlgo = createAsyncThunk('simulation/getOptimizationAlgo', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  } 

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/optimisationAlgo`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await axios(apiConfig);

    if(result.data.message){
      return rejectWithValue(`Optimisation Algo: ${result.data.message}`);
    }
    
    return result.data.data;

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBase = createAsyncThunk('simulation/getBase', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    // url: `${process.env.REACT_APP_API_URL}/base`,
    url: `${process.env.REACT_APP_API_URL}/algorithm`, // previously  /base
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await axios(apiConfig);

    if(result.data.message){
      return rejectWithValue(`Base: ${result.data.message}`);
    }
    
    return result.data.data;

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFollower = createAsyncThunk('simulation/getFollower', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    // url: `${process.env.REACT_APP_API_URL}/follower`,
    url: `${process.env.REACT_APP_API_URL}/algorithm`, // previously /follower
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await axios(apiConfig);

    if(result.data.message){
      return rejectWithValue(`Follower: ${result.data.message}`);
    }
    
    return result.data.data;

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createNewSimulation = createAsyncThunk('simulation/createNewSimulation', async(formData, { dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/simulation`,
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(formData)
  }

  try {
    const result = await axios(apiConfig);

    if (result.data.message) {
      return rejectWithValue(`${result.data.message}. Failed to Create Simulation`);
    }

  } catch (err) {
    return rejectWithValue('Failed to Create Simulation');
  }
})

export const getSimulationAnalysis = createAsyncThunk('simulation/getSimulationAnalysis', async (data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  
  const bodyData = {
    "action": data.action,
    "algo_id": data.algo_id,
    "amount": data.amount,
    "base": data.base,
    "buy_at": data.buy_at,
    "duration": data.duration,
    "end_time": data.end_time,
    "follower": data.follower,
    "from_date": data.from_date,
    "instrument_id": data.instrument_id,
    "optimisation_algo_id": data.optimisation_algo_id,
    "sell_at": data.sell_at,
    "start_time": data.start_time,
    "stoploss": data.stoploss,
    "to_date": data.to_date
  }

  // console.log(bodyData)
  
  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/simulation/${data.id}`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: bodyData,
  };
  
  try {
      const result = await axios(apiConfig);

      if (result.data.message) {
        return rejectWithValue(`Simulation Analysis: ${result.data.message}`);
      } 

      // console.log(SingleSim[20])

      // simulationBarChart to be removed after api data configured
      // return {"summary": null, "daily_pl": null}; 
      return {"summary": result.data.summary, "daily_pl": result.data.dailypls || SingleSim[20]}; 
  } catch (err) {
    return rejectWithValue('Failed to get analysis data');
  }
});

export const getSimulationFileExports = createAsyncThunk('simulation/getSimulationFileExports', async (values,{dispatch, rejectWithValue}) => {
  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/export-data/${values.id}`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': `Bearer ${access_token}`,
    }
  };

  try {
    const result = await axios(apiConfig);

    if (result.data.message) {
      return rejectWithValue(result.data.message);
    } 

    

    // console.log('api res= ',URL.createObjectURL(fileBlob))
    return result.data;
} catch (err) {
  return rejectWithValue(err);
}
});

export const simulationSlice = createSlice({
    name: 'simulation',
    initialState,
    reducers: {
        setSimulationLoading: (state, action) => {
            state.loading = action.payload
        },
        setSimulationError: (state,action) => {
            state.error = action.payload
        },
        setAllSimulation: (state,action) => {
            state.allSimulationData = action.payload
        },           
        setAlgoData: (state,action) => {
            state.algoData = action.payload
        },
        setOptimisedAlgoData: (state,action) => {
            state.optimisationAlgoData = action.payload
        },
        setInstrumentData: (state,action) => {
            state.instrumentData = action.payload
        },
        setSimulationTableLoading: (state, action) => {
            state.simulationTableLoading = action.payload
        },     
        setTotalPage: (state, action) => {
          state.totalPage = action.payload
        },  
        setItemsPerPage: (state, action) => {
          state.itemsPerPage = action.payload
        },
        setCurrentPage: (state, action) => {
          state.currentPage = action.payload
        },
        setModalVisibility: (state, action) => {
          state.modalVisibility = action.payload
        },
        setFormSubmittedSuccessfully: (state, action) => {
          state.submittedSuccessfully = action.payload
        },
        setBaseData: (state,action) => {
          state.baseData = action.payload
        },
        setFollowerData: (state,action) => {
          state.followerData = action.payload
        },
        setBaseExclude: (state,action) => {
          state.baseExclude = action.payload
        },
        setFollowerExclude: (state, action) => {
          state.followerExclude = action.payload
        },
        setSelectedAlgo: (state, action) => {
          state.selectedAlgo = action.payload
        },
        setSelectedTicker: (state, action) => {
          state.selectedTicker = action.payload
        },
        setTickerIntervalData: (state, action) => {
          state.tickerIntervalData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSimulation.pending, (state) => {
              state.loading = true;
            })
            .addCase(getAllSimulation.fulfilled, (state, action) => {
              state.loading = false;
              state.allSimulationData = action.payload.data;
              state.currentPage = action.payload.current_page;
              state.totalPage = action.payload.total_pages;
              state.itemsPerPage = action.payload.items_per_page;
            })
            .addCase(getAllSimulation.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getInstrument.pending, (state) => {
              state.loading = true;
            })
            .addCase(getInstrument.fulfilled, (state, action) => {
              state.loading = false;
              state.instrumentData = action.payload;
            })
            .addCase(getInstrument.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getAlgo.pending, (state) => {
              state.loading = true;
            })
            .addCase(getAlgo.fulfilled, (state, action) => {
              state.loading = false;
              state.algoData = action.payload;
            })
            .addCase(getAlgo.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getOptimizationAlgo.pending, (state) => {
              state.loading = true;
            })
            .addCase(getOptimizationAlgo.fulfilled, (state, action) => {
              state.loading = false;
              state.optimisationAlgoData = action.payload;
            })
            .addCase(getOptimizationAlgo.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getBase.pending, (state) => {
              state.loading = true;
            })
            .addCase(getBase.fulfilled, (state, action) => {
              state.loading = false;
              state.baseData = action.payload;
            })
            .addCase(getBase.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getFollower.pending, (state) => {
              state.loading = true;
            })
            .addCase(getFollower.fulfilled, (state, action) => {
              state.loading = false;
              state.followerData = action.payload;
            })
            .addCase(getFollower.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(createNewSimulation.pending, (state) => {
              state.loading = true;
            })
            .addCase(createNewSimulation.fulfilled, (state) => {
              state.loading = false;
              state.submittedSuccessfully = true;
              state.modalVisibility = false;
            })
            .addCase(createNewSimulation.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getSimulationAnalysis.pending, (state) => {
              state.loading = true;
            })
            .addCase(getSimulationAnalysis.fulfilled, (state, action) => {
              state.loading = false;
              state.simulationAnalysisData = action.payload.summary;
              state.simualtionDailyPLData = action.payload.daily_pl;
            })
            .addCase(getSimulationAnalysis.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })


            .addCase(getSimulationFileExports.pending, (state) => {
              state.loading = true;
            })
            .addCase(getSimulationFileExports.fulfilled, (state, action) => {
              state.loading = false;
              state.simulationResultFile = action.payload;
            })
            .addCase(getSimulationFileExports.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
            })
    }
});


export const { 
    setSimulationLoading,
    setSimulationError,
    setAllSimulation,
    setAlgoData,
    setOptimisedAlgoData,
    setInstrumentData,
    setSimulationTableLoading,
    setTotalPage,
    setItemsPerPage,
    setCurrentPage,
    setModalVisibility,
    setFormSubmittedSuccessfully,
    setBaseData,
    setFollowerData,
    setBaseExclude,
    setFollowerExclude,
    setSelectedAlgo,
    setSelectedTicker,
    setTickerIntervalData
} = simulationSlice.actions;
  
export default simulationSlice.reducer;
import { configureStore } from '@reduxjs/toolkit';
import customRootReducer from './customRootReducer';

const middlewares = [];

const store = configureStore({
	reducer: customRootReducer(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})


export default store;
